<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-card>
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            enctype="multipart/form-data"
        >
          <div class="row">
            <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
            <div class="col-md-6">
              <b-form-group label="Title" label-for="title">
                <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                >
                  <b-form-input
                      id="title"
                      v-model="form.title"
                      :state="errors.length > 0 ? false : null"
                      name="title"
                      :value="form.title"
                      placeholder="Enter Contact Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Contact Description" label-for="description">
                <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                >
                  <Input
                      type="textarea"
                      id="description"
                      v-model="form.description"
                      :state="errors.length > 0 ? false : null"
                      name="description"
                      placeholder="Enter Contact Description"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <!--///////////////////////////////////////////////////////////-->

            <div class="col-md-6">
              <b-form-group label="Sub-Title" label-for="sub_title">
                <validation-provider
                    #default="{ errors }"
                    name="sub_title"
                    rules="required"
                >
                  <b-form-input
                      id="sub_title"
                      v-model="form.sub_title"
                      :state="errors.length > 0 ? false : null"
                      name="sub_title"
                      placeholder="Enter Contact Sub-Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Contact Sub-Description" label-for="sub_description">
                <validation-provider
                    #default="{ errors }"
                    name="sub_description"
                    rules="required"
                >
                  <Input
                      type="textarea"
                      id="sub_description"
                      v-model="form.sub_description"
                      :state="errors.length > 0 ? false : null"
                      name="sub_description"
                      placeholder="Enter Contact Sub-Description"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <!--///////////////////////////////////////////////////////////-->

            <div class="col-md-6">
              <b-form-group label="Phone" label-for="phone">
                <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                >
                  <b-form-input
                      id="phone"
                      v-model="form.phone"
                      :state="errors.length > 0 ? false : null"
                      name="phone"
                      placeholder="Enter Contact Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Email" label-for="email">
                <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                >
                  <b-form-input
                      id="email"
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="Enter Contact Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

          </div>
          <!-- submit buttons -->
          <b-button type="submit" variant="primary" @click="validationForm">
            Update
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormFile,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  vSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      form: {
        id:"",
        title:"",
        description:null,
        sub_title:"",
        sub_description:"",
        email:"",
        phone:"",

        /*about_title: "",
        about_image: null,
        about_description:null,*/
      },
      // about_image: null,
      required,
      email,
    };
  },

  mounted() {
    this.updateForm();
  },
  methods: {
    async updateForm(){
      const contact = await this.callApi("get", "/app/cms/contact/us");

      if(1){
        this.form.id = contact.data.id;
        this.form.title = contact.data.title;
        this.form.description = contact.data.description;
        this.form.sub_title = contact.data.sub_title;
        this.form.sub_description = contact.data.sub_description;
        this.form.email = contact.data.email;
        this.form.phone = contact.data.phone;
      }
    },

    Update() {
      axios
          .put("/app/cms/contact/us/"+this.form.id, this.form)
          .then((res) => {
            this.updateForm();
            this.s(res.data.message);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              if (e.response.data.errors.title)
                this.e(e.response.data.errors.title[0]);
              if (e.response.data.errors.description)
                this.e(e.response.data.errors.description[0]);
              if (e.response.data.errors.sub_title)
                this.e(e.response.data.errors.sub_title[0]);
              if (e.response.data.errors.sub_description)
                this.e(e.response.data.errors.sub_description[0]);
              if (e.response.data.errors.email)
                this.e(e.response.data.errors.email[0]);
              if (e.response.data.errors.phone)
                this.e(e.response.data.errors.phone[0]);
            }
          });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BFormFile,
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect
  },
  computed: {
  }
};
</script>

<style></style>